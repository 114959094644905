<template>
	<notifications width="15%" position="top right" />
</template>
<script>
export default {
	name: "notification",
};
</script>
<style lang="scss">
.vue-notification {
	padding: 10px;
	margin: 20px 15px 5px;

	font-size: 20px;

	color: #ffffff;
	background: #44a4fc;
	border-left: 5px solid #187fe7;

	&.warn {
		background: #ffb648;
		border-left-color: #f48a06;
	}

	&.error {
		background: #e54d42;
		border-left-color: #b82e24;
	}

	&.success {
		background: #68cd86;
		border-left-color: #42a85f;
	}
}
</style>
