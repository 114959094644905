import Vue from 'vue';
import TokenService from './../services/TokenService';

Vue.mixin({
    methods: {
        can(permission) {
            if (!permission) return true;
            permission = permission.split(' ');
            return !!this.$store.state.permissionList.find(item => {
                return permission.find(elem => elem === item);
            });
        }
    }
})