<template>
	<div v-if="loading" class="mainLoading">
		<div class="circle-spin-4"></div>
	</div>
</template>

<script>
export default {
	name: "Loading",
	computed: {
		loading() {
			return this.$store.state.loading;
		},
	},
};
</script>

<style scoped lang="scss">
.mainLoading {
	z-index: 9999999;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
}
.circle-spin-4 {
	--size: 44px;
	--stroke-width: calc(var(--size) / 8);
	--color: currentColor;
	--animation-duration: 1s;
	position: relative;
	width: var(--size);
	height: var(--size);
}

.circle-spin-4::before,
.circle-spin-4::after {
	content: "";
	position: absolute;
	inset: 0;
	border-width: var(--stroke-width);
	border-style: solid;
	border-color: var(--color) var(--color) transparent transparent;
	border-radius: 50%;
	transform: rotate(0deg);
	animation: var(--animation-duration) infinite circle-spin-4-animation;
}

.circle-spin-4::before {
	animation-timing-function: cubic-bezier(0.5, 0, 0.75, 0);
}

.circle-spin-4::before {
	animation-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
}

@keyframes circle-spin-4-animation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
</style>
