import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import "./filters";
import "./plugins";
import "./mixins";
import "./assets/styles/main.scss";
import "./assets/fonts/fonts.css";
import "vue-search-select/dist/VueSearchSelect.css";
import jwtDecode from "jwt-decode";
import axios from "axios";
import TokenService from "@/services/TokenService";
import LanguageService from "@/services/LanguageService";
import EIMZOVuePlugin from "./vue-eimzo";
import { IMaskComponent } from "vue-imask";

import "swiper/swiper-bundle.css";

let baseURL = process.env.VUE_APP_BASE_URL_DOMAIN;
store.commit("setBaseUrl", baseURL);

const instance = axios.create({
	baseURL,
	headers: {
		common: {
			"X-Requested-With": "XMLHttpRequest",
		},
	},
});

let checkRefresh = false;

function waitForVariable() {
	return new Promise((resolve) => {
		const checkVariable = () => {
			if (checkRefresh === false) {
				resolve();
			} else {
				setTimeout(checkVariable, 100);
			}
		};
		checkVariable();
	});
}

async function refreshTokenForRequest() {
	try {
		if (!localStorage.Authorization) return;

		let expireTime = Number(jwtDecode(localStorage.Authorization).exp) * 1000;
		const timestamp = expireTime;
		const decreaseBySeconds = 5;

		const newTimestamp = timestamp - decreaseBySeconds * 1000;

		const currentTime = Date.now();

		if (currentTime > newTimestamp) {
			if (checkRefresh === true) {
				console.log(checkRefresh);
				await waitForVariable();
				console.log(checkRefresh);
				return;
			}
			checkRefresh = true;
			let refreshToken = localStorage.Refresh;
			const response = await axios.post(`${baseURL}api-auth/Login/refreshToken?refreshtoken=${refreshToken}`);
			TokenService.setRefreshApi(response.data.result);
			checkRefresh = false;
			return;
		}
	} catch (err) {
		checkRefresh = false;
		console.log(err);
		TokenService.removeAll();
	}
}

instance.interceptors.request.use(
	async (config) => {
		await refreshTokenForRequest();

		config.headers["Accept-Language"] = LanguageService.getLanguage();

		if (TokenService.getToken()) {
			config.headers.Authorization = "Bearer " + TokenService.getToken();
		}

		return config;
	},
	(error) => Promise.reject(error)
);

instance.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (!(error.response && error.response.data && error.response.data.error.code === 401)) {
			if (error?.response?.data?.error?.message) {
				Vue.prototype.$notification.error(error.response.data.error.message);
			} else if (error?.response?.status) {
				Vue.prototype.$notification.error(error.response.statusText);
			} else {
				Vue.prototype.$notification.error("Xatolik yuz berdi");
			}
			return Promise.reject(error);
		}
		if (error.response && error.response.data && error.response.data.error.code === 401) {
			TokenService.removeAll();
		}
	}
);

Vue.config.productionTip = false;

Vue.use(EIMZOVuePlugin);

Vue.prototype.$api = instance;

Vue.component("imask-input", IMaskComponent);

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app");

