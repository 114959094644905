const LanguageService = {
	languages: [
		{
			lang: "Eng",
			id: "en",
		},
		{
			lang: "Рус",
			id: "ru",
		},
		{
			lang: "O'zb",
			id: "uz",
		},
		{
			lang: "Ўзб",
			id: "cyrl",
		},
	],
	getLanguage() {
		return localStorage.getItem("lang") || "ru";
	},
	setLanguage(lang, i18n) {
		i18n.locale = lang;
		localStorage.setItem("lang", lang);
		window.location.reload();
	},
	needNotMethod(obj) {
		let result;
		if (typeof obj === "object" && obj) {
			// let lang = Store.state.clng;
			let lang = this.getLanguage();
			result = obj ? obj[lang] : null;

			if (!result) {
				result = this.getExistingTranslation(obj);
			}
		} else {
			result = obj;
		}
		return result || "";
	},
	getExistingTranslation(value) {
		for (const i in this.languages) {
			const translation = value[this.languages[i].id];
			if (translation) {
				return translation;
			}
		}
	},
};
export default LanguageService;
