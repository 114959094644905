import uz from "@/plugins/vee-validate/UzValidateMessage.json";

export default {
	notification: {
		error: "Xatolik",
		warning: "Ogohlantirish",
		success: "Muvaffaqiyatli",
	},
	validation: uz.messages,

	binary: "Бинарное",
	textual: "Текстовое",
	numeric: "Числовое",
	for: "За",
	against: "Против",

	methodsAuction: "Boshlangʼich narxni pasaytirish uchun oʼtkaziladigan auksion",
	test: "TEST",
	tashkent: "Toshkent",
	contacts: "Aloqa",
	address: "Manzil",
	workingHours: "Ish vaqti",
	moreAboutServices: "Xizmatlar haqida ko'proq",
	submitApplication: "Ilovani yuborish",
	bannerText: "Raqamli biznesingiz uchun kompleks yechimlar",
	whoAreWe: "Biz kimmiz?",
	whyAreWe: "Nega biz?",
	services: "Sizning biznesingizni rivojlantirish uchun hamma narsa",
	getAnOffer: "Bizning tijorat taklifimizni ko'rib chiqing",
	partners: "Biz mijozlarimizning ishonchini qadrlaymiz",
	technologies: "Biz foydalanadigan texnologiyalar",
	gallery: "Galereya",
	faq: "Savollar",
	feedback: "Mulohazalar",
	company: "Kompaniya haqida",
	partnersMenu: "Hamkorlar",
	servicesMenu: "Xizmatlar",
	vacancies: "Vakansiyalar",
	copyrights: "Barcha huquqlar himoyalangan.",
	copyrightsInfo: "Ushbu saytda hech qanday ma'lumot ommaviy taklif emas.",
	yourName: "Ismingiz",
	yourPhoneNumber: "Telefon raqamingiz",
	yourEmail: "Pochtangiz",
	captcha: "rasmdagi matnni kiriting",
	getOffer: "Taklif oling",
	error_404: "XATO 404",
	errorTitle: "Bu siz izlayotgan narsa bo'lmasligi mumkin.",
	errorSubtitle:
		"Kechirasiz, siz so'ragan sahifa mavjud emas. Manzilning yozilishini tekshiring yoki asosiy sahifaga o'ting.",
	backToHomePage: "Bosh sahifaga qaytish",
	errorText: "Nimadir noto'g'ri bajarildi",
	error: "Xato",
	success: "Muvaffaqiyatli",
	messageSuccessfullySent: "Sizning xabaringiz muvaffaqiyatli yuborildi.",
	homeMainBannerTitle: "O'zbekiston Respublikasi Elektron kooperatsiya portali",
	quickRegistration: "Tez ro'yxatdan o'tish",
	startNow: "Hoziroq boshlang",
	callCenter: "Qo'ng'iroqlar markazi",
	signIn: "Tizimga kirish",
	signUp: "Ro'yxatdan o'tish",
	logOut: "Chiqish",
	personalCabinet: "Shaxsiy kabinet",
	home: "Bosh sahifa",
	allSections: "Barcha bo'limlar",
	procurement: "Xarid qilish",
	planGraphic: "Reja-jadval",
	shop: "Elektron katalog",
	auction: "Auksion",
	selection: "Tanlash",
	tender: "Tender",
	directContracts: "To'g'ridan-to'g'ri xaridlar",
	purchasingRange: "Xarid qilish diapazoni",
	priceCriteria: "Narx mezonlari",
	calculator: "Kalkulyator",
	information: "Ma'lumotlar",
	statistics: "Statistika",
	standardFormsAndContracts: "Standart shartnomalar va shakllar",
	normativeActs: "Normativ hujjatlar",
	complaintsCommissionDecisions: "Shikoyat komissiyasi qarorlari",
	instructions: "Yo'riqnomalar",
	catalog: "Katalog",
	news: "Yangiliklar",
	allNews: "Barcha yangiliklar",
	tariffs: "Tariflar",
	subjectsOfProcurement: "Xarid qilish sub'ektlari",
	unifiedRegisterOfUnscrupulousPerformers: "Insofsiz ijrochilarning yagona reestri",
	singleSupplierRegister: "Yagona yetkazib beruvchilar reyestri",
	registerOfCorporateCustomers: "Korporativ mijozlar reestri",
	registryOfBudgetCustomers: "Byudjet mijozlari reestri",
	supplierRegister: "Yetkazib beruvchilar reestri",
	specializedOrganizations: "Ixtisoslashgan tashkilotlar",
	operatorServices: "Operator xizmatlari",
	videoInstruction: "Video ko'rsatma",
	purchasingMethods: "Sotib olish usullari",
	suppliers: "Yetkazib beruvchilar",
	supplier: "Yetkazib beruvchi",
	location: "Chilonzor-2, Arnasoy ko'chasi, 76",
	inn: "STIR",
	mfo: "MFO",
	checkingAccount: "Hisob raqami",
	aboutPortal: "Portal haqida",
	publicOffer: "Ommaviy oferta",
	formOfDocuments: "Hujjatlar shakli",
	technicalSupport: "Texnik yordam",
	category: "Kategoriya",
	commissionAmount: "Komissiya miqdori",
	summa: "Summa",
	pledge: "Zakalat",
	total: "Jami",
	days: "Kun",
	hours: "Soat",
	minutes: "Daqiqa",
	seconds: "Sekund",
	orderGlass: "Narxlar stakani",
	startingAmount: "Boshlang'ich narx",
	currentAmount: "Joriy miqdor",
	nextPrice: "Keyingi narx",
	apply: "Arizani topshirish",
	biddingHistory: "narx takliflari tarixi",
	price: "Narx",
	decrease: "Kamayish",
	date: "Sana",
	time: "Vaqt",
	name: "Nomi",
	amountShort: "Miqdori",
	amount: "Miqdor",
	minimumAmount: "Minimal miqdor",
	unitShort: "Birlik",
	description: "Tavsif",
	estimatedDeliveryTime: "Yetkazib berish muddati",
	lotInformation: "Lot haqida ma'lumot",
	customerInformation: "Xaridor haqida ma'lumot",
	filter: "Filtrlash",
	search: "Qidirish",
	lotNumber: "Lot raqami",
	region: "Hudud",
	chooseRegion: "Hududni tanlang",
	chooseCategory: "Guruhni tanlang",
	clearFilter: "Filtrni tozalash",
	cancel: "Bekor qilish",
	show: "Ko'rsatish",
	searchByTaxNumber: "STIR bo'yicha qidirish",
	organizationName: "Tashkilot nomi",
	procedureType: "Jarayon turi",
	enterTheAmountOfTheCommercialOffer: "Tijorat taklifi miqdorini kiriting",
	selectThePercentageOfTheCollateral: "Zakalat foizini tanlang",
	collateralAmount: "Zakalat miqdori",
	theAmountOfOperatorServices: "Operator xizmatlarining miqdori",
	close: "Yopish",
	contest: "Auksion",
	customer: "Xaridor",
	provider: "Yetkazib berish",
	contractNo: "Shartnoma raqami",
	productName: "Mahsulot nomi",
	customerName: "Mijoz nomi",
	workAndServices: "ish, xizmatlar",
	contractAmount: "Shartnoma summasi",
	PerformerName: "Ijrochi nomi",
	noData: "Ma'lumot yo'q",
	totalStartingCost: "Umumiy boshlang'ich qiymati",
	calendarTerm: "kalendar muddati",
	deliveryAddress: "Yetkazib berish manzili",
	contractNumber: "Shartnoma raqami",
	contractDate: "Shartnoma sanasi",
	productDetail: "Mahsulot detallari",
	customerInn: "Xaridorning STIR raqami",
	customerRegion: "Xaridor hududi",
	customerDistrict: "Xaridor tumani",
	customerAddress: "Xaridor manzili",
	customersPersonalAccountWithTheTreasury: "Xaridorning G'aznachilikdagi shaxsiy hisobi",
	perforemersRegion: "Ijrochi viloyati",
	performersDistrict: "Ijrochi tumani",
	performersAddress: "Ijrochi manzili",
	performerInformation: "Ijrochi haqida ma'lumot",
	questionCategories: "Savol guruhlari",
	allSuppliers: "Barcha yetkazib beruvchilar",
	voted: "Ovoz berildi",
	signedContracts: "Imzolangan shartnomalar",
	participants: "Ishtirokchilarning",
	products: "Tovarlarning",
	perday: "Kuniga",
	usefulLinks: "Foydali havolalar",
	enterProductNumberOrName: "Mahsulotning nomi yoki raqamini kiriting...",
	instructionSupplierRegistration: "Yetkazib beruvchi uchun ro'yxatdan o'tish qo'llanmasi",
	instructionSupplierAuction: "Yetkazib beruvchi uchun auksion qo'llanmasi",
	instructionCustomerRegistration: "Xaridorni ro'yxatdan o'tkazish bo'yicha ko'rsatma",
	instructionCustomerPlanGraphic: "Xaridor rejasi bo'yicha ko'rsatma",
	instructionCustomerAuction: "Buyurtmachi auksioni",
	instructionSupplierShop: "Yetkazib beruvchi uchun do'kon qo'llanmasi",
	instructionCustomerPlanSchedule: "Buyurtmachi uchun qo'llanma (reja-jadval)",
	instructionsCustomerAuction: "Buyurtmachi uchun qo'llanma (auktsion)",
	instructionsCustomerShop: "Buyurtmachi uchun qo'llanmasi (do'kon)",
	instructionsCustomerRegistration: "Buyurtmachi uchun qo'llanma (ro'yxatdan o'tish)",
	instructionsCustomerBudget: "Ma'lumotnoma yuklab olish bo'yicha yo'riqnoma",
	instructionsCustomerBudgetAll:
		"Budjet tashkilotlarining ro'yxatdan o'tishi, reja-jadvallarini qo'shishi va xarid qilishlari uchun ishlab chiqilgan yo'riqnoma",
	instructionService: "Ish va xizmatlarni joylashtirish bo'yicha yo'riqnoma",
	suppliersInstructionsAuction: "Ishtirokchi uchun qo'llanma (auktsion)",
	suppliersInstructionsShop: "Ishtirokchi qo'llanmasi (do'kon)",
	suppliersInstructionsRegistration: "Ishtirokchi uchun qo'llanma (ro'yxatdan o'tish)",
	instructionCustomerPlanScheduleSize: "97 kB",
	instructionsCustomerAuctionSize: "700 kB",
	instructionsCustomerShopSize: "1720 kB",
	instructionsCustomerRegistrationSize: "223 kB",
	suppliersInstructionsAuctionSize: "1178 kB",
	suppliersInstructionsShopSize: "1591 kB",
	suppliersInstructionsRegistrationSize: "160 kB",
	learnMore: "Batafsil ma'lumot",
	latestNews: "So'ngi yangiliklar",
	publishedDate: "Nashr etilgan sana",
	shareOnSocialMediaNetworks: "Ijtimoiy tarmoqlarda baham ko'ring",
	bargainingSubject: "Savdo mavzusi",
	month: "Oy",
	chooseYear: "Yilni tanlang",
	year: "Yil",
	subjectOfTheAuction: "Auksion mavzusi",
	action: "Amallar",
	quarter: "Chorak",
	view: "Ko'rish",
	priceCriteriaForPublicProcurement: "Davlat xaridlari uchun narx mezonlari",
	subjectOfProcurement: "Xarid qilish mavzusi",
	budgetCustomers: "Byudjet mijozlari",
	corporateCustomers: "Korporativ mijozlar",
	purchaseType: "Sotib olish turi",
	product: "Mahsulot",
	noLimitSet: "Cheklanmagan",
	notLessThan5WorkingDays: "5 ish kunidan kam emas",
	notLessThan30WorkingDays: "30 ish kunidan kam emas",
	nameOfGoodsAllowedForPurchaseFromASingleSupplier:
		"Bitta etkazib beruvchidan sotib olishga ruxsat berilgan tovarlar (ishlar, xizmatlar) nomi",
	reason: "Sabab",
	advancedSearch: "Kengaytirilgan qidiruv",
	country: "Mamlakat",
	chooseCountry: "Mamlakatni tanlang",
	manufacturer: "Ishlab chiqaruvchi",
	unitPrice: "Birlik uchun narx",
	from: "dan",
	until: "gacha",
	more: "Batafsil",
	expirationDate: "Tugash muddati",
	showLess: "Kamroq ko'rsatish",
	showMore: "Ko'proq ko'rsatish",
	orderName: "Buyurtma nomi",
	deadline: "Tugash muddati",
	productQuantity: "Tovar miqdori",
	showPeriod: "Davrni ko'rsatish",
	procurementInfoByRegion: "Mintaqalar bo'yicha xaridlar to'g'risidagi ma'lumotlar",
	transactionAmount: "Tranzaksiya miqdori",
	saving: "Tejamkorlik",
	dealAmount: "Bitimlar miqdori",
	top5SoldProductCategories: "Eng ko'p sotiladigan 5 ta mahsulot toifasi",
	categoryName: "Guruh nomi",
	selectionOfTheBestOffers: "Tanlov",
	collateral: "Garov",
	stateProcurements: "Davlat xaridlari to'g'risida",
	ResolutionsOfThePresident: "O`zbekiston Respublikasi Prezidentining qarorlari",
	LawsOfUzb: "O'zbekiston Respublikasi qonunlari",
	DecreesOfThePresident: "O`zbekiston Respublikasi Prezidentining Farmonlari",
	provisions: "Qoidalar",
	docText4544:
		"O‘zbekiston Respublikasi Prezidentining «Davlat xaridlari tizimini yanada takomillashtirish va davlat xaridlari jarayoniga tadbirkorlik sub'ektlarini keng jalb qilish chora-tadbirlari to‘g‘risida» gi Qarori 2019 yil 5 dekabr', PQ-4544-son",
	docText3016:
		'"Xarid qilish tartib-taomillarini tashkil etish va o‘tkazish tartibi to‘g‘risidagi nizomni tasdiqlash haqida"gi O‘zbekiston Respublikasi Prezidenti huzuridagi loyiha boshqaruvi milliy agentligining buyrug‘i (O‘zbekiston Respublikasi Adliya vazirligi tomonidan 2018 yil 26 mayda ro‘yxatdan o‘tkazildi, ro‘yxat raqami 3016)',
	docText3015:
		'O‘zbekiston Respublikasi Prezidenti huzuridagi loyiha boshqaruvi milliy agentligi direktorining "Maxsus axborot portali operatorining davlat xaridlarini tashkil etish hamda o‘tkazish borasidagi faoliyati to‘g‘risidagi nizomni tasdiqlash haqida"gi buyrug‘i  (O‘zbekiston Respublikasi Adliya vazirligi tomonidan 2018 yil 21 mayda ro‘yxatdan o‘tkazildi, ro‘yxat raqami 3015)',
	docText3013:
		'O‘zbekiston Respublikasi Prezidenti huzuridagi loyiha boshqaruvi milliy agentligi direktorining "Davlat xaridlari sohasidagi shikoyatlarni ko‘rib chiqish tartibi to‘g‘risidagi nizomni tasdiqlash haqida"gi buyrug‘i  (O‘zbekiston Respublikasi Adliya vazirligi tomonidan 2018 yil 14 mayda ro‘yxatdan o‘tkazildi, ro‘yxat raqami 3013)',
	docText3057:
		'O‘zbekiston Respublikasi Prezidenti huzuridagi loyiha boshqaruvi milliy agentligining "Davlat xaridlarini o‘tkazish bo‘yicha xizmatlarni ko‘rsatish huquqiga ega bo‘lgan ixtisoslashgan tashkilotlar to‘g‘risidagi nizomni tasdiqlash haqida"gi buyrug‘i  (O‘zbekiston Respublikasi Adliya vazirligi tomonidan 2018 yil 20 avgustda ro‘yxatdan o‘tkazildi, ro‘yxat raqami 3057)',
	docText3953:
		"Oʼzbekiston Respublikasi Prezidentining «Oʼzbekiston Respublikasining «Davlat xaridlari toʼgʼrisida»gi Qonunini amalga oshirish chora-tadbirlari toʼgʼrisida» gi Qarori 2018 yil 27 sentyabrь,\tPQ-3953-son",
	docTextAuctionContracts:
		'"Boshlang‘ich narxni pasaytirish bo‘yicha auksion" platformasida elektron davlat xaridlari natijalari bo‘yicha tovarlar yetkazib berish bo‘yicha namunaviy shartnoma',
	docTextShopContracts:
		'"Elektron katalog" platformasida elektron davlat xaridlari natijalari bo‘yicha tovarlar yetkazib berish bo‘yicha tovarlar yetkazib berish bo‘yicha namunaviy shartnoma',

	servicesText3:
		"Xizmat bank mijozlariga bank operatsiyalarini bevosita o‘zlarining “1S” va “Didox” buxgalteriya tizimlaridan amalga oshirish imkonini beradi.",
	servicesText4: "Biz ishonchli yetkazib beruvchilar, tovarlar sifati va xavfsiz to‘lov haqida qayg‘uramiz.",
	numberTrade: "Savdolar soni",
	tradingVolume: "Savdolar hajmi",
	signInOrder: "Buyurtma berish uchun kiring",
	requestPrice: "Narx so'rash",
	count: "Soni",
	productDescription: "Tovar tasnifi",
	minMaxParty: "Min/maks partiya",
	tradingUntilActive: "Savdo tugash vaqti",
	deliveryTime: "Yetkazib berish vaqti",
	brand: "Marka",
	manufacturerCountry: "Ishlab chiqarilgan mamlakat",
	shelfLife: "Yaroqlilik muddati",
	warrantyPeriod: "Kafolat davri",
	warrantyTerms: "Kafolat shartlari",
	prodYearAndMonth: "Ishlab chiqarilgan yili va oyi",
	techSpecifications: "Texnik parametrlar",
	epidemiological: "Epidemiologik xulosa",
	environmental: "Ekologik ekspertiza",
	autoRenewal: "Avtomatik uzaytirish",
	conclusions: "Veterinariya-sanitariya xulosasi",
	sertificate: "Sertifikat mavjudligi",
	phytosanitary: "Fitosanitariya xulosasi",
	license: "Litsenziyaning mavjudligi",
	veterinary: "Veterinariya xulosasi",
	nationalManufacturer: "Mahalliy ishlab chiqaruvchi",
	deliveryRegion: "Yetkazib berish hududi",
	deliveryRegions: "Yetkazib berish hududlari",
	relativeProducts: "O'xshash tovarlar",
	showAll: "Barchasi",
	request: "So'rov",
	protocolText01: "Tanlov uchun xarid hujjatlarini tasdiqlash bayyonomasi",
	protocolText02: "Muvofiqlik bayoni",
	protocolText03: "Tanlovni tiklash bayoni",
	protocolText04: "Tanlovni bekor qilish bayoni",
	protocolText05: "Tanlov g'olibini aniqlash bayoni, ball usulida",
	protocolText06: "Tanlov g'olibini aniqlash bayoni, eng arzon narx usulida",
	protocolText07: "Tanlovning texnik qismini baholash bayoni",
	protocolText08: "Eng yaxshi taklifni tanlashni to'xtatib turish bayoni",
	protocolText09: "Tender o'tkazish boyicha hisobot",
	protocolText10: "Tenderni tiklash bo'yicha bayon",
	protocolText11: "Rasmiy talablarga muvofiqligini tekshirish bayoni",
	protocolText12: "Tender g'olibini aniqlash bayoni, ball usulida",
	protocolText13: "Tender g'olibini aniqlash bayoni, eng arzon narx usulida",
	protocolText14: "Tenderni bekor qilish bayoni",
	protocolText15: "Tanlovning malakaviy qismini baholash bayoni",
	protocolText16: "Tenderni texnik qismini baholash bayoni",
	protocolText17: "Tender to'xtatilganligi to'g'risidagi bayon",
	protocolText18: "Tender xujjatlarini tasdiqlash bayoni",
	modelContracts: "Namunaviy shartnomalar",
	typicalProtocols: "Namunaviy bayonnomalar",
	fileInstruction: "Qo'llanmalar",
	registrationQuestions: "Ro'yxatdan o'tish bilan bog'liq savollar",
	scheduleQuestions: "Reja-jadval bilan bog'liq savollar",
	scheduleAuctions: "Auksion bilan bog'liq savollar",
	scheduleShops: "Do'kon bilan bog'liq savollar",
	selectionQuestions: "Tanlov bilan bog'liq savollar",
	tenderQuestions: "Tender bilan bog'liq savollar",
	questionsPayment: "To'lovlar bilan bog'liq savollar",
	questionsOperator: "Operatorning komission xizmatlari bilan bog'liq savollar",
	popularQuestions: "Ko'p beriladigan savollar",

	otherDocuments: "Boshqa hujjatlar",
	select_all: "Barchasini tanlash",
	plan_schedule: "Reja jadvali",
	incompleteTransactions: "Amalga oshmagan bitimlar",
	end: "-------------------DO NOT PASS THIS LINE--------------------------",

	main_title: "Davlat xaridlarining maxsus axborot portali",
	sourceOfFinancing: "Moliyalashtirish manbasi",
	district: "Tuman",
	deliveryConditions: "Yetkazib berish sharti",
	customerPhone: "Buyurtmachi telefon",
	form_plan_schedule: "Reja jadvallari namunalarini shakllantirish",
	unifiedRegisterOfTheContract: "Shartnomalarning yagona reestri",

	instructionCustomerSelectionTender: "Tanlov tender o'tkazish bo'yicha buyurtmachi uchun qo'llanma",
	instructionSupplierSelectionTender: "Ishtirokchilar uchun tanlash va tender uchun qoʼllanma",

	instructionCustomerSelectionTenderSize: "477kB",
	instructionSupplierSelectionTenderSize: "463kB",

	docTextEthical: "Odob-ahloq qoidalari",
	docTextEthicalSize: "30kb",

	infact: "Fakt bo‘yicha",
	quarterly: "Choraklik",
	exactDate: "Aniq sana",
	yes_or_no: "Ha/Yo'q",
	agree_or_disagree: "Roziman/Rozi emasman",
	critical: "Muhim",
	preferably: "Afzal",
	systemic: "Tizimli",
	expert: "Ekspert tomonidan",

	role: "Rol",
	clear: "Tozalash",
	dealDate: "Bitim sanasi",
	selection_prepayment: "Oldindan to'lov",
	selection_without_prepayment: "Oldindan to'lovsiz",
	selection_payment_order: "To'lov tartibi",

	type: "Savdo turi",
	selectionQuatity: "Tanlov",

	supplier_1: "O'zbekiston Respublikasi Oliy Majlisi Senati",
	supplier_2: "O'zbekiston Respublikasi Davlat Bojxona Qo'mitasi",
	supplier_3: "O‘zbekiston Respublikasi Investitsiyalar va tashqi savdo vazirligi",
	supplier_4: "O'zbekiston Respublikasi Davlat soliq qo'mitasi",
	supplier_5: "O'zbekiston Respublikasi Oliy Majlisi Qonunchilik Palatasi",
	// new leng
	directContractsUpperCase: "To'gridan to'gri shartnomalar",
	otherTreatiesUpperCase: "Boshqa shartnomalar",
	auctionUpperCase: "Auktsion",
	shopUpperCase: "Do'kon",
	selectionUpperCase: "Tanlov",
	tenderUpperCase: "Tender",
	nameOne: "Nomlanishi",
	tnVed: "TIF ТN",
	unitMeasurement: "O'lchov birligi",
	gostStandards: "Davlat standartlari (ISO)",
	startDate: "Boshlanish muddati",
	endDate: "Tugash vaqti",
	importAlternativeRequest: "Import muqobil so'rovi",
	priceFrom: "Narx dan",
	priceTo: "Narx gacha",

	decreesofTheCabinetOFMinisters: "O‘zbekiston Respublikasi Vazirlar Mahkamasining qarorlari",
	docText833:
		"O‘zbekiston Respublikasi Vazirlar Mahkamasining 2019 yil 30 sentabrdagi 833-sonli  “Investitsiyalar va tashqi savdo vazirligi huzuridagi Raqamli transformatsiya markazining elektron kooperatsiya portalida axborotni joylashtirish va xaridlarni amalga oshirish tartibini tasdiqlash to‘g‘risida”gi qarori",
	docText276:
		"O‘zbekiston Respublikasi Vazirlar Mahkamasining 2022 yil 20 maydagi 276-sonli “Davlat xaridlarini amalga oshirish bilan bog‘liq tartib-taomillarni tashkil etish va o‘tkazish tartibi to‘g‘risidagi nizomni tasdiqlash haqida”gi  qarori",
	eShop: "Elektron katalog",
	anAuction: "Auksion",

	link_1: "O'zbekiston Respublikasi Oliy Majlisi Senati",
	link_2: "O'zbekiston Respublikasi Davlat Bojxona Qo'mitasi",
	link_3: "O‘zbekiston Respublikasi Investitsiyalar, sanoat va savdo vazirligi",
	link_4: "O‘zbekiston Respublikasi Iqtisodiyot va Moliya vazirligi huzuridagi Soliq qo‘mitasi",
	link_5: "O'zbekiston Respublikasi Oliy Majlisi Qonunchilik Palatasi",

	feedback2: "Qayta aloqa",
	supplier_1_title: "Навоий кон металлургия",
	supplier_2_title: "Узбекнефтегаз",
	supplier_3_title: "Алмалык кон металлургия",
	supplier_4_title: "AKFA",
	supplier_1_description:
		'Navoiy kon-metallurgiya kombinati" aksiyadorlik jamiyati ("NKMK" AJ) jahonda oltin ishlab chiqaruvchi yirik kompaniyalar o‘ntaligiga kiradi. Kombinat yer osti boyliklari zaxiralarini geologik qidirish, qazib olish va qayta ishlashdan to tayyor mahsulot olishgacha bo‘lgan ishlab chiqarish jarayonlari to‘liq amalga oshiriladigan sanoat klasteridir. Kombinat zamonaviy kon-transport va texnologik uskunalar, ilg‘or texnologiyalar bilan jihozlantirilgan konlar, zavodlarga ega zamonaviy korxonadir.',
	supplier_2_description:
		"“O‘zbekneftgaz” xolding korporatsiyasi neft va gazni qidirish, qazib olish, tashish, saqlash, qayta ishlash va sotish bilan shug‘ullanadigan O'zbekistondagi eng yirik davlat kompaniyasi.",
	supplier_3_description:
		"O’zining 70 yillik tarixi davomida mamlakatimiz iqtisodiy salohiyati rivojiga munosib hissa qo’shib kelayotgan “Olmaliq KMK” AJ O’zbekistondagi eng yirik sanoat korxonalaridan biri bo’lib, uning ishlab chiqarish quvvati Toshkent, Jizzax, Namangan, Surxondaryo viloyatlarida joylashgan mis-molibden, qo’rg’oshin-rux, oltin va kumush konlariga asoslanadi.",
	supplier_4_description:
		"За годы существования компания AKFA зарекомендовала себя как надежного и ответственного производителя, в продукции которого вы можете быть уверены.",

	listOfRecommended: "Tavsiya etilgan hujjatlar ro'yxati",
	theVolumeOfIndustrial: "Sanoat mahsulotini ishlab chiqarish hajmi",
	proposalForCooperation: "Hamkorlik bo'yicha taklif",
	inportAndExportVolme: "Import va eksport hajmi",
	stateRealEstate: "Davlat ko'chmas mulki",
	budgetPurtchases: "Byudjet xaridlari: reja-jadvallar",
	corporateProcurement: "Korporativ xaridlar: reja-jadvallar",
	operatorDetailsForNon: "Byudjetdan tashqari tashkilotlar uchun operator rekvizitlari",
	electronicCooperationPortal: "O'zbekiston Respublikasi Elektron kooperatsiya portali",
	requisitesForBudgetary: "Byudjet tashkilotlari uchun rekvizitlar",
	specialist: "Vositachilik yig'imi to'lovi uchun maxsus h/r",
	pr: "H/r",
	ls: "H/R",

	street: "ko'cha",
	phone: "Telefon raqami",
	eMail: "Pochta manzili",
	electronicCooperationPortal3: "Elektron kooperatsiya portali",
	for: "За",
	against: "Против",
	onlineExhibitions: "Onlayn ko'rgazmalar",
	sum: "so'm",
	pickup: "Olib ketish",
	specifyQuantity: "Sonini kiriting",
	generalCharacteristics: "Umumiy xususiyatlar",
	numberOfBidsSubmitted: "Joylashtirilgan ofertalar soni",
	goodsOfOrganizationsRelatedPersonsWithDisabilities: "Nogironlar jamiyatiga tegishli tashkilotlarning tovarlari",
	electricalEquipmentUzeltekhsanoat: "Elektrotexnika mahsulotlari (O'zeltexsanoat)",
	TNVEDCode: "TIF TN kodi",
	mechanismQuoteRequestAvailableForCustomers: "Narx so'rovi mexanizmi Narx so'rovi buyurtmachilar uchun mavjud",
	documentsTransferredWithTheGoods: "Tovarlar bilan birga o'tkazilgan hujjatlar ro'yxati",
	tvedCode: "TIF TN kodi",
	itemOfExpenses: "Xarajatlar moddasi",
	totalAmount: "Umumiy miqdor",
	pledgeAmount: "Zakalat miqdori",
	commissionFee: "Vositachilik to'lovi miqdori",
	totalBlockAmount: "Umumiy band miqdori",
	functionalCharacteristics: "Funksional xususiyatlar",
	binary: "Ikkilik",
	textual: "Matnli",
	numeric: "Raqamli",
	logIn: "Kirish",
	startPrice: "Jarayonning boshlang'ich narxi",
	contractNumber: "Shartnoma raqami",
	amountOfDeal: "Shartnoma summasi",
	startingPrice: "Boshlang'ich narxi",
	cooperation: "Kooperatsiya portali",
	cooperation_child: "Rivojlanishda",
	registerOfManufacturersDealers: "Ishlab chiqaruvchilar/dilerlar reestri",
	pay: "To'lash",
	unPrePaid: "Oldindan to'lovsiz",
	prePaid: "Oldindan toʻlov",
	setPrePaidPercent: "Oldindan to'lov foizini belgilang",
	notRequestPrice: "Siz mahsulotingiz uchun narxni so'ra olmaysiz",
	developing: "Ishlab chiqilmoqda",
	poweredbyCDT: "Raqamli transformatsiya markazi",
	mechanismQuoteRequestNotAvailable: "Narx so'ro'vi mexanizmi ishlab chiqilmoqda",
	totalDealAmount: "Bitimlarning umumiy summasi (million so'm)",
	minFt: "O‘zbekiston Respublikasi Investitsiyalar, sanoat va savdo vazirligi",
	limitIsNotChecked: "Cheklov belgilanmagan",
	contractsCount: "Imzolangan shartnomalar",
	contractsPrice: "Bitimlarning umumiy summasi (million so'm)",
	participantsCount: "Ishtirokchilar",
	productsCount: "Tovarlar",
	bannerText1: "Raqamli transformatsiya markazi",
	bannerText1Under: "Elektron davlat xaridlari tizimining operatori",
	bannerText2: "O'zbekiston Respublikasi Prezidentining tadbirkorlar bilan<br /> 'Ochiq muloqoti' doirasida",
	bannerText2Under: "100 ta loyihaviy takliflar",
	yearly: "Yilda",
	aboutPortalTitle:
		"Portal O‘zbekiston Respublikasi Prezidentining “Sanoat kooperatsiyasini yanada rivojlantirish va talabi yuqori mahsulotlar ishlab chiqarishni kengaytirish chora-tadbirlari toʻgʻrisida”gi 2019 yil 1-maydagi PQ-4302-sonli Qarori va Oʻzbekiston Respublikasi Vazirlar Mahkamasining “Sanoat yarmarkalari va elektron kooperatsiya birjasi faoliyatini tashkil etish chora-tadbirlari toʻgʻrisida”gi 2019 yil 30 sentyabrdagi 833-son qaroriga muvofiq ishlab chiqilgan.",
	aboutPortalSubtitle:
		"Elektron kooperatsiya portali - bu mahalliy ishlab chiqaruvchilar oʻrtasida sanoat sohasidagi kooperatsiya aloqalarini mustahkamlash va rivojlantirish, sanoat mahsulotlari ishlab chiqarishni kengaytirish va kichik biznes subyektlarini sanoat faoliyatiga keng jalb qilish maqsadida yaratilgan maxsus savdo maydonchasidir.",
	testingMode: "Veb-sayt ishlab chiqilmoqda",
	instructionForUsers: "Ro'yxatdan o'tish",
	instructionForProducts: "Mahsulot joylashtirish yo'riqnomasi",
	executor: "Ijrochi",
	periodOfExecution: "Bajarish muddati",
	protocols: "Protokollar",
	dealStatus: "Bitim holati",
	sourceFunded: "Manba moliyalashtirilgan",
	numberOfAddedItems: "Qo'shilgan elementlar soni",
	participantType: "Ishtirokchi turi",
	manufacturerDealer: "Ishlab chiqaruvchi/diler",
	dealer: "Diler",
	calculate: "Hisoblash",
	myFilters: "Mening filtrlarim",
	onlineFair: "Yarmarka",
	authForVote: "Ovoz berish uchun ro'yhatdan o'ting",
	timerText: "Savdolar boshlanish vaqti: 03.07.2023 9:00",
	instructionForCert: "Sertifikatlar qo‘shish yo‘riqnomasi",
	instructionForDiler: "Dilerlik qo‘shish yo‘riqnomasi",
	day2: "Kun",
	day3: "Kun",
	day4: "Kun",
	certificated: "Sertifikatlangan",
	notCertificated: "Sertifikatlanmagan",
	inAYear: "Yiliga",
	titleOfWithoutPercent: "Shartnomaning namunaviy shakli (Elektron katalog, to'liq to'lov)",
	titleOfWithPercent: "Shartnomaning namunaviy shakli (Elektron katalog, oldindan to'lov)",
	eSignKeyError: "Parol noto'g'ri kiritildi",
	instructionForCellWithoutPrePayment: "Savdo qilish jarayoni yo'riqnomasi (oldindan to'lovsiz)",
	instructionForRejectingOrEnablingCell: "To'lovlarni amalga oshirish va shartnomalarni bekor qilish yo'riqnomasi",
	inDevelopingMode: "Ishlab chiqilmoqda",
	abrobation: "Сайт апробация жараёнида",
	questionnaire: "So'rovnoma",
	gotoQuestionaree: "So'rovnomani to'ldiring",
	typeOfPayment: "To'lov turi",
	artisans: "Hunarmandlar tomonidan ishlab chiqarilgan mahsulotlar",
	bannerText4:
		"12-15-dekabr kunlari Toshkent shahridagi “O‘zekspomarkaz” Milliy ko‘rgazmalar majmuasida yil davomida hududlarda o‘tkazib kelingan sanoat yarmarkalarining yakuniy bosqichi - Respublika tarmoqlararo sanoat yarmarkasi bo‘lib o‘tmoqda.",
	registerOfActiveTrades: "Faol savdolar reestri",
	numberOffer: "Oferta raqami",
	maxAmount: "Maksimal miqdor",
	allActiveTrades: "Barcha aktiv savdolar",
	ourNews: "Afzallik va qulayliklar",
	titleOfFeatutures:
		"Raqamli transformatsiya markazining Elektron kooperatsiya portalida joriy qilingan afzallik va qulayliklari",
	feature1:
		"Savdo jarayonlarida qatnashgan ishtirokchilarning shahsiy kabinetida savdo tugagandan so‘ng <b>g‘olib chiqgan ishtirokchini ko‘rish imkoni</b> yaratildi.",
	feauture2Title: "<b>So‘rovlarni</b> quyidagi qismlarga ajratildi:",
	feature2Text1: "Barcha so‘rovlar;",
	feature2Text2: "Mening so‘rovlarim (faol so‘rovlar);",
	feauture2FinalText:
		"Shuningdek, barcha so‘rovlar va xabarnomalarni ma'lumotlar bazasiga saqlash vazifasi bajarildi.",
	feauture3Title:
		"Savdo jarayoni tugagandan so‘ng ushbu savdodagi <b>to‘liq tarixini</b> ko‘rish imkoni yaratildi, jumladan:",
	feauture3Text1: "so‘rov qachon yuborilgan;",
	feauture3Text2: "so‘rov qachon qabul qilingan;",
	feauture3Text3: "savdo boshlangan va tugash vaqti;",
	feauture3Text4: "savdoga xabarnoma olgan ishtirokchilar ro‘yxati;",
	feauture3Text5: "ishtirokchilarning savdodagi bergan narx takliflari, miqdori va vaqti;",
	feauture3Text6: "Savdoda g‘alaba qozongan ishtirokchining ma'lumotlari.",
	feauture4:
		"Portalda tuzilgan <b>shartnomalar reestrlarini shakllantirish va yuritib borish</b> mexanizmi takomillashtirildi.",
	feauture5:
		"Shartnoma shakllangan kundan boshlab buyurtmachi tomonidan to‘lov amalga oshirilmasa buyurtmachidan <b>18 bank kunidan</b> keyin <b>jarima undirish</b> va <b>shartnomani bekor qilish</b> funksiyasi joriy qilindi.",
	feauture6:
		"Sertifikat talab qilinadigan <b>kodlarni toifalarga ajratish</b> vazifasi bajarildi, (shart/shart emas).",
	feauture7:
		"TIF TN kodning boshidagi 4 ta raqami birxil bo‘lgan taqdirda, savdo boshlanganligi haqida <b>SMS-xabarnoma</b> yuborish funksiyasi joriy qilindi.",
	feauture8:
		"Sertifikat va dilerlik muddati tugaganga qadar yoki  faol oferta kodi bo‘yicha import aniqlanganda <b>xabarnoma berish funksiyasi</b> joriy qilindi.",
	feauture9: "Shartnomani bekor qilinganda <b>jarima tafsilotlarini</b> ko‘rsatish imkoni yaratildi.",
	feauture10Title: "Ofertalarning moderasiyadan o‘tish tartibi <b>raqamlashtirildi</b>, ya'ni:",
	feauture10Text1: "Barcha kelib tushgan ofertalar navbati bilan o‘tkazilishi;",
	feauture10Text2:
		"Portal foydalanuvchilari shaxsiy kabineti orqali o‘z mahsulotining moderasiyadan o‘tish navbatini doimiy monitoring qilib borish imkoni yaratildi;",
	feauture10Text3:
		"Agar navbatsiz holda oferta moderasiyadan o‘tgan taqdirda ham, mazkur oferta darhol faol bo‘lmaydi, o‘z navbati kelganda tizim avtomatlashtirilgan holda faol holatiga o‘tkazadi.",
	feauture10FinalText:
		"Moderasiyaga navbat mexanizmi: agar navbatda boshqa ofertalar mavjud bo‘lsa oferta ma'qullangandan so‘ng darhol faol bo‘lmaydi, kutish rejimiga o‘tkaziladi.",
	feauture11:
		"Savdo jarayonidagi shaffoflikni ta'minlash maqsadida, Hisob palatasi, Raqobatni rivojlantirish va iste'molchilar huquqlarini himoya qilish qo‘mitasi hamda  Monopoliyaga qarshi kurashish qo‘mitasi bilan birgalikda o‘zaro <b>ma'lumot almashinuvi (integrasiya)</b> ishlari amalga oshirildi.",
	feauture12:
		"Portal foydalanuvchilari uchun <b>real vaqt rejimida joriy savdolar ro‘yxati va ma'lumotlarini ko‘rib borish</b> imkoniyati yaratildi.",
	readTheFeautures: "Tanishib chiqdim",
	notificationText: "Xabarnoma",
	support: "Qo'llab-quvvatlash",
	code2022: "TIF TN Kodlari (2022)",
	offerUntilActive: "Oferta faolligining tugash vaqti",
	cooperAdress: "100027, Toshkent shahar, Shayxontohur, Qoratosh ko'chasi, 2A",
	socialMedia: "Ijtimoiy tarmoqlar",
	typeSms: "Telefon raqamizga yuborilgan kodni kiriting",
	send: "Yuborish",
	comment: "Izoh",
	participantAccountInStore: "Mijozning jamg'armadagi hisob raqami",
	monthCountOfPayment: "To'lov qilish oyi",
	selectRegion: "Iltimos, hududni tanlang",
	fullSearchFromList: "Ma'lumotnoma olish uchun kengaytirilgan qidiruv",
	getInformation: "Ma'lumotnoma olish",
	typeFirst3LettersOfProduct: "mahsulotning boshidagi 3 ta harfini yozing",
	typeFirst3LettersOfCode: "TIF TN kodining boshidagi 4 ta raqamini yozing",
	pictureOfItem: "Tovar rasmi",
	announcement: "E'lon",
	rejectAnswer1: "Tovar (ish, xizmat)ning mavjud emasligi",
	rejectAnswer2: "Tovar (ish, xizmat) miqdorining kamligi yoki yetarli emasligi",
	rejectAnswer3: "Tovar (ish, xizmat)ning sifati, kafolati, texnik parametrlari talab darajasiga muvofiq kelmasligi",
	rejectAnswer4: "Yetkazib berish muddatining uzoqligi",
	rejectAnswer5: "Boshqa sabablar",
	rejectionAnnouncement:
		"Tovarlar (ishlar, xizmatlar)ni mahalliy ishlab chiqaruvchilardan xarid qilish imkoniyatini majburiy tartibda o‘rganish talablariga rioya qilmaslik tegishli javobgarlikka sabab bo‘lishi mumkin.",
	productNameMin: "Tovar nomi 3 ta harfdan kam bo'lmasligi kerak",
	sendedRequestToDMBAt: "So'rov DMBATga yuborildi",
	restoringTitle: "ДИҚҚАТ ЭЪЛОН !!!",
	restoringText: "Порталда техник ишлар олиб борилмоқда, портал қисқа муддатда ишга тушишини маълум қиламиз.",
	notFound: "Sizning so'rovingiz bo'yicha hech qanday natija topilmadi",
	loginForInfo: "Ma'lumotnoma olish uchun kiring",
	goToPersonalCabinet: "Shaxsiy kabinetga o'tish",
	registerOfSingleBusnises: "Yagona yetkazib beruvchilar reyestri",
	tableSupllierName: "Tovar (ish, xizmat) yetkazib beruvchi nomi",
	tableSupllierType: "Tovar (ish, xizmat) nomi",
	statusName: "Holati",
	codeOfKlassifikator: "Klassifikator kodi",
	dateOfSupplierIn: "Реестрга киритилган сана",
	purpose: "Asos",
	buxoroTopProducts: "Buxoro viloyati top mahsulotlari",
	numberOfActiveOffers: "Aktiv ofertalar soni",
	disabilityCompanyText:
		"\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0 Hurmatli mijoz, Siz tanlagan oferta Nogironlar jamiyatiga tegishli tashkilotning tovarlari hisoblanadi, ushbu tovar bo‘yicha portalda to‘g‘ridan-to‘g‘ri savdo bo‘lib o‘tishini ma'lum qilamiz.",
	numberOfOffers: "Ofertalar soni",
	active: "Aktiv",
	all: "Barchasi",
	registrationDate: "Ro‘yxatdan o‘tgan sana",
	greenShopping: "Yashil xaridlar",
	budget: "Byudjet",
	typeOrganization: "Tashkilot turi",
	getAllCompanies: "Ro'yxatdan o'tgan tashkilotlar",
	numberOfOffersByCorp: "Ishlab chiqaruvchi ofertalar soni",
	numberOfOffersByDiler: "Diler ofertalar soni",
	corporative: "Korporativ",
	providerOrCustomer: "Tashkilot holati",
	customer2: "Buyurtmachi",
	direct_contract: "To'g'ridan-to'g'ri shartnoma",
	next: "Keyingi",
	back: "Oldingi",
	enktCode: "MSK kodi",
	epFindings: "Epidemiologik xulosalar",
	verOp: "Veterinariya xulosalar",
	phyConc: "Fitosanitariya xulosalar",
	vetSan: "Veterinariya-sanitariya xulosalar",
	enAssessment: "Ekologik ekspertiza",
	no: "Yo'q",
	yes: "Ha",
	thereIs: "Mavjud",
	instructionGovernment: "Mahsulot qo‘shish uchun murojaat yuborish bo‘yicha yo‘riqnoma",
	goods: "Mahsulotlar",
	work_and_services: "Ishlar va xizmatlar",
	enter_name: "Ismingizni kiriting",
	phone_number: "Telefon raqam",
	enter_phone_number: "Telefon raqamingizni kiriting",
	your_email: "E-Mail manzilingizni kiriting",
};
