import Vue from 'vue'

Vue.mixin({
  methods: {
    makeCoverImage(link, image) {
      if (link.includes("youtube.com")) {
        const newImageLink = link.split("/")
        return `https://img.youtube.com/vi/${newImageLink[newImageLink.length - 1]}/hqdefault.jpg`
      } else {
        return image ? image : ""
      }
    }
  }
})

