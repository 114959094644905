import i18n from "../plugins/i18n";

export const selectionStatuses = {
	NEW: 1,
	INSUFFICIENT_FUNDS: 2,
	MODERATION: 3,
	DENIED: 4,
	RE_EDITED: 5,
	DELETED: 6,
	ACTIVE: 7,
	ACTIVE_ON_THE_SITE: 8,
};

export const selectionPaymentSchedule = {
	EXACT_DATE: 1,
	QUARTERLY: 2,
	IN_FACT: 3,
};

export const selectionPaymentScheduleList = {
	[selectionPaymentSchedule.EXACT_DATE]: i18n.t("exactDate"),
	[selectionPaymentSchedule.QUARTERLY]: i18n.t("quarterly"),
	[selectionPaymentSchedule.IN_FACT]: i18n.t("infact"),
};

export const criteriaTypeOfValues = {
	BINARY: 1,
	TEXT: 2,
	NUMBER: 3,
	NULL: null,
};

export const criteriaTypeOfValuesList = {
	[criteriaTypeOfValues.BINARY]: i18n.t("binary"),
	[criteriaTypeOfValues.TEXT]: i18n.t("textual"),
	[criteriaTypeOfValues.NUMBER]: i18n.t("numeric"),
	[criteriaTypeOfValues.NULL]: i18n.t(""),
};

export const tenderTypes = {
	TENDER: 1,
	SELECTION: 2,
};

export const selectionPaymentOrder = {
	PREPAYMENT: 1,
	WITHOUT_PREPAYMENT: 2,
};

export const selectionPaymentOrderList = {
	[`${selectionPaymentOrder.PREPAYMENT}`]: i18n.t("selection_prepayment"),
	[`${selectionPaymentOrder.WITHOUT_PREPAYMENT}`]: i18n.t("selection_without_prepayment"),
};

export const voteStatuses = {
	FOR: 1,
	AGAINST: 2,
};

export const voteStatusNames = {
	[`${voteStatuses.FOR}`]: i18n.t("for"),
	[`${voteStatuses.AGAINST}`]: i18n.t("against"),
};

export const companyTypes = {
	DILER: 1,
	MANUFACTURER: 2,
};

export const companyTypesNames = {
	[companyTypes.DILER]: i18n.t("dealer"),
	[companyTypes.MANUFACTURER]: i18n.t("manufacturer"),
};
