import Vue from "vue";
import Notifications from "vue-notification";

// documentation: https://www.npmjs.com/package/vue-notification
Vue.use(Notifications);

const notification = {
	error(text) {
		Vue.prototype.$notify({
			position: "top right",
			text,
			type: "error",
		});
	},
	warn(text) {
		Vue.prototype.$notify({
			position: "top right",
			text,
			type: "warn",
		});
	},
	success(text) {
		Vue.prototype.$notify({
			position: "top right",
			text,
			type: "success",
		});
	},
};

Vue.prototype.$notification = notification;

export default notification;
