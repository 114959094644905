import i18n from "@/plugins/vue-i18n-config";

const TimeService = {
    getTimeDiff(from, to) {
		const diffTime = new Date(to) - new Date(from);
        return this.getTime(diffTime)
    },
	getTimeFromNumber(number) {
		return this.getTime((number * 1000 * 60 * 60 * 24));
	},
	getTime(number) {
		let time = Math.floor(number / 1000 / 60 / 60 / 24);
		if (time < 0) return 'Неверное время';

		const days = time / 30;
        
        return days >= 12 ? `${Math.floor(time / 12)} ${i18n.t('year')}` :
					(days < 12 && days >= 1 ? 
					`${Math.floor(days)} ${i18n.t('month')}` :
					`${Math.floor(time)} ${i18n.t('days')}`);
	}
}
export default TimeService;