import Vue from "vue";
import VueI18n from "vue-i18n";
import LanguageService from "@/services/LanguageService";
import uz from "./translations/uz";
import ru from "./translations/ru";
import en from "./translations/en";
import cyrl from "./translations/cyrl";

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: LanguageService.getLanguage(),
	messages: { uz, ru, en, cyrl },
});

export default i18n;
