import store from "../store";
import jwtDecode from "jwt-decode";
const TOKEN_KEY = "Authorization";
const REFRESH_TOKEN_KEY = "Refresh";
const EXPIRE_TIME = "Expire";
const PERMISSIONS = "permissions";
const ESIID = "esiId";

const TokenService = {
	setRefreshApi(data) {
		localStorage.setItem(TOKEN_KEY, data.access_token);
		localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh_token);
		localStorage.setItem(PERMISSIONS, data.permissions);
		this.saveStructureId(jwtDecode(data.access_token).StructureId);
	},
	setEsiId(data) {
		localStorage.setItem(ESIID, data);
	},
	getEsiId() {
		return localStorage.getItem(ESIID);
	},
	removeEsiId() {
		localStorage.removeItem(ESIID);
	},
	getToken() {
		return localStorage.getItem(TOKEN_KEY);
	},
	removeToken() {
		localStorage.removeItem(TOKEN_KEY);
	},
	saveToken(token) {
		localStorage.setItem(TOKEN_KEY, token);
	},
	getPermissions() {
		return localStorage.getItem(PERMISSIONS);
	},
	savePermissions(permissions) {
		store.commit("setPermissionList", permissions);
		localStorage.setItem(PERMISSIONS, permissions);
	},
	removePermissions() {
		store.commit("setPermissionList", []);
		localStorage.removeItem(PERMISSIONS);
	},
	saveRefreshToken(token) {
		localStorage.setItem(REFRESH_TOKEN_KEY, token);
	},
	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_KEY);
	},
	removeRefreshToken() {
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	},
	getStructureId() {
		return localStorage.getItem("structure_id");
	},
	saveStructureId(id) {
		localStorage.setItem("structure_id", id);
	},
	removeStructureId() {
		localStorage.removeItem("structure_id");
	},
	saveExpireTime(time) {
		localStorage.setItem(EXPIRE_TIME, time);
	},
	getExpireTime() {
		return parseInt(localStorage.getItem(EXPIRE_TIME));
	},
	removeExpireTime() {
		localStorage.removeItem(EXPIRE_TIME);
	},

	removeAll() {
		this.removeToken();
		this.removeRefreshToken();
		this.removeExpireTime();
		this.removePermissions();
		this.removeStructureId();
		this.removeEsiId();
	},
};

export default TokenService;

