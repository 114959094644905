<template>
	<div>
		<div id="app">
			<router-view :key="$store.getters.defineSelectionOrTender($route.path)" />
			<notification />
		</div>
		<Loading />
		<!-- <Restoring /> -->
		<!-- <Snowlfake /> -->
	</div>
</template>
<script>
import Notification from "@/components/shared-components/Notification";
import Loading from "./components/loading.vue";
import Snowlfake from "./components/winter/snowflake";
import Restoring from "./components/pages/Restoring";

export default {
	name: "app",
	components: { Notification, Loading, Snowlfake, Restoring },
	async created() {
		await this.getServerTime();
		await this.getWorkingTime();
		this.changeSavedTimeWithInterval();
		this.getServerTimeWithInterval();
	},
	methods: {
		changeServerTime() {
			if (!this.$store.getters.getServerTime) {
				return;
			}
			let serverTime = this.$store.getters.getServerTime;
			let newServerTime = this.$moment(new Date(serverTime)).add(1, "s");
			this.$store.commit("setServerTime", newServerTime);
		},
		changeSavedTimeWithInterval() {
			setInterval(() => {
				this.changeServerTime();
			}, 1000);
		},
		getServerTime() {
			return this.$api.get("/api-client/Client/GetByDateTime").then((response) => {
				this.$store.commit("setServerTime", response.data.result.dateTimes);
			});
		},
		getServerTimeWithInterval() {
			setInterval(() => {
				this.getServerTime();
			}, 1000 * 60 * 5);
		},
		async getWorkingTime() {
			if (localStorage.Authorization) {
				this.$store.commit("setLoadingStatus", true);
				const response = await this.$api.get("/api-shop/LotRequest/GetTimeCheck");
				console.log(response.data.result);
				this.$store.commit("hybridSetter", {
					key: "isWorkingHour",
					data: response.data.result.isWorkingHours,
				});
				this.$store.commit("hybridSetter", {
					key: "isWorkingDay",
					data: response.data.result.isWorkingDay,
				});
				this.$store.commit("setLoadingStatus", false);
			}
		},
	},
};
</script>

<style></style>
