import Vue from 'vue';
import LanguageService from './services/LanguageService';
import TimeService from './services/time-service';
import monthString from '@/helpers/monthString';

Vue.filter('translate', function (value) {
	return LanguageService.needNotMethod(value);
});

Vue.filter('price', function (price, decSeparator = ' ') {
	return price ? price.toLocaleString().replace(/,/g, decSeparator) : price;
});

Vue.filter('moneyFormat', function (number) {
	if (!number) {
		return number
	}

	let numberString = String(number);

	number = numberString.split('.')[0];
	let part = numberString.split('.')[1];
	if (!(Number(number))) {
		return number
	}
	let arr = ('' + number).split('').reverse();
	arr = arr.map((item, index) => {
		if (!((index + 1) % 3)) {
			item = ' ' + item
		}
		return item;
	});
	return (arr.reverse().join('')) + (part ? ('.' + part) : '');
});

Vue.filter('toFix', function (value) {
	if (typeof value === 'number') {
		return value ? value.toFixed(2) : value;
	} else {
		return value
	}
});

Vue.filter('pluckFromObject', (objectArray, fields) => {
	if (Vue.prototype.$R.is(Array, fields)) {
		return Vue.prototype.$R.map(Vue.prototype.$R.path(fields))(objectArray);
	}

	return Vue.prototype.$R.pluck(fields)(objectArray);
});

Vue.filter('removeDuplicates', function (objectArray) {
	return Array.from(new Set(objectArray));
});

Vue.filter('mergeArrays', function (array1, array2) {
	Array.prototype.push.apply(array1, array2);
});

Vue.filter('convertToYear', function (days) {
	return TimeService.getTimeFromNumber(days);
});

Vue.filter('monthString', function (val) {
	return monthString(val);
});

Vue.filter('toUpperCase', (val) => val.toUpperCase())