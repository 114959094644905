import Vue from "vue";
import Vuex from "vuex";
import LanguageService from "@/services/LanguageService";
import { tenderTypes } from "@/static/statusIds";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		userInfo: {
			tin: null,
		},
		baseUrl: "",
		serverTime: null,
		windowWidth: null,
		menus: [],
		clng: LanguageService.getLanguage(),
		contractData: null,
		loading: false,
		isWorkingHour: null,
		isWorkingDay: null,
		codeTnVedForDoc: {},
		permissionList: [],
	},
	mutations: {
		setPermissionList(state, data) {
			if (typeof data === "string") {
				data = data.split(",");
			}
			state.permissionList = data;
		},
		setCodeTnVed(state, payload) {
			state.codeTnVedForDoc = payload;
		},
		setQuestionaree(state, payload) {
			state.userInfo.isQuestionnaire = payload;
		},
		hybridSetter(state, payload) {
			state[payload.key] = payload.data;
		},
		setLoadingStatus(state, payload) {
			state.loading = payload;
		},
		setServerTime(state, newTime) {
			state.serverTime = newTime;
		},
		setWindowWidth(state, newWidth) {
			state.windowWidth = newWidth;
		},
		setBaseUrl(state, baseUrl) {
			state.baseUrl = baseUrl;
		},
		setLanguage(state, lang) {
			state.clng = lang;
		},
		setUserInfo(state, user) {
			state.userInfo = user;
		},
		setContractData(state, data) {
			state.contractData = data;
		},
	},
	getters: {
		getServerTime: (state) => {
			return state.serverTime;
		},
		getCurrentLanguage: (state) => {
			return state.clng;
		},
		isLoggedIn: (state) => {
			return state.userInfo.tin;
		},
		defineSelectionOrTender: (state, getters) => (routePath) => {
			const pathArray = routePath.split("/");
			let type = null;

			if (pathArray[1] === "selection") {
				type = tenderTypes.SELECTION;
			} else if (pathArray[1] === "tender") {
				type = tenderTypes.TENDER;
			}

			getters.setTenderType(type);

			return type;
		},
		setTenderType: (state) => (type) => {
			if (type) {
				localStorage.setItem("tenderType", +type);
			}
		},
		getTenderType: (state) => () => {
			return +localStorage.getItem("tenderType");
		},
		getTenderRoute: (state, getters) => (routeName) => {
			let rName = routeName;

			if (+getters.getTenderType() === +tenderTypes.TENDER) {
				rName = routeName.replaceAll("Selection", "Tender");
			}

			return rName;
		},
	},
	actions: {},
	modules: {},
});

